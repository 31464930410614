import { easepick } from '@easepick/core';
import { AmpPlugin } from '@easepick/amp-plugin';
import { RangePlugin } from '@easepick/range-plugin';
import { PresetPlugin } from '@easepick/preset-plugin';


function checkUrlEnds(strToCheck) {
  const currentUrl = window.location.href;
  return currentUrl.endsWith(strToCheck);
}

// Example usage:
const endsWithString = "artist_reports";
if (checkUrlEnds(endsWithString)) {
  console.log("The URL ends with '" + endsWithString + "'");

document.addEventListener('DOMContentLoaded', () => {
  console.log('artist report init');

  const pickerConfig = {
    css: [
      "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css"
    ],
    zIndex: 10,
    format: "DD MMM YYYY",
    plugins: [
      AmpPlugin,
      RangePlugin,
      PresetPlugin
    ],
    AmpPlugin: {
      dropdown: {
        months: true,
        years: true,
        minYear: 2020
      }
    },
    PresetPlugin: {
      position: "right"
    }
  };

  const picker1 = new easepick.create({
    ...pickerConfig,
    element: document.querySelector("#datepicker1")
  });

  const picker2 = new easepick.create({
    ...pickerConfig,
    element: document.querySelector("#datepicker2")
  });

  console.log('Pickers initialized', picker1, picker2);
});

}